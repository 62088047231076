<template>
    <div class="myCoupon">
        <el-tabs v-model="active" @tab-click="handleClick">
            <el-tab-pane label="全部"></el-tab-pane>
            <el-tab-pane label="已过期"></el-tab-pane>
            <el-tab-pane label="已使用"></el-tab-pane>
            <el-tab-pane label="未使用"></el-tab-pane>
        </el-tabs>
        <div class="myCoupon_list" v-if="dataList.length">
            <div class="myCoupon_list_item" v-for="(item,index) in dataList" :key="index">
                <div :class="['myCoupon_list_item_top', item.isOverdue == 1 || item.isUse == 1 ? 'overdue' : '']">
                    <div class="myCoupon_list_item_top_left">
                        <p>¥{{ item.price }}</p>
                        <p>满足{{ item.enablePrice }}元可用</p>
                    </div>
                    <div class="myCoupon_list_item_top_right">
                        <button v-if="item.isUse == 0" @click="goPlay()">去使用</button>
                    </div>
                </div>
                <div class="myCoupon_list_item_bottom">
                    <div class="myCoupon_list_item_bottom_time" v-if="item.effectiveType == 0">
                        <p>有效期：</p>
                        <p>
                            <span>{{ getDate(item.effectiveBtime) }}</span>
                            <span>至</span>
                            <span>{{ getDate(item.effectiveEtime) }}</span>
                        </p>
                    </div>
                    <div class="myCoupon_list_item_bottom_time" v-else>
                        <p>有效天数：</p>
                        <p>
                            <span>{{ item.effectiveDay }}</span>
                        </p>
                    </div>
                    <div class="myCoupon_list_item_bottom_stretch">
                        <p>
                            <span>使用范围：</span>
                            <span v-for="(item2,index2) in item.availableCoursePackageList" :key="index2">{{ item2 }}</span>
                        </p>
                        <!-- <p>
                            <img src="@/assets/mySelf/pull-down.png" alt />
                        </p>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="noMore" v-if="!dataList.length">
            <div class="coursediv_empty_img">
                <img src="@/assets/class_empty.png" alt="">
            </div>
            暂无数据</div>
    </div>
</template>

<script>
import { coupon_list } from '../../api/myself';
export default {
    name: 'myCoupon',
    data() {
        return {
            active: 0,
            dataList: []
        }
    },
    mounted() {
        this.coupon_list();
    },
    methods: {
        handleClick() {
            this.coupon_list();
        },
        coupon_list() {
            coupon_list({ type: this.active }).then(res => {
                console.log(res);
                this.dataList = res.data.data
            })
        },
        getDate(e) {
            return new Date(e + 8 * 3600 * 1000).toJSON().substr(0, 10).replace("T", " ")
        },
        goPlay(){
            this.$router.push({
                path:'/course'
            })
        }
    }
}
</script>

<style scoped>
.noMore {
    text-align: center;
    width: 100%;
    color: #999;
    font-size: 14px;
    margin: 40px 0;
}
</style>